import { getRoutes, getNavigation } from "@/data/utils";

export const SITE_PATH = "Project";

const MASTER_ROUTES = [
  {
    path: "/",
    label: "home",
    component: () => import("@/views/home"),
    hidden: false,
  },
  {
    path: "/cars",
    label: "cars",
    component: () => import("@/views/cars"),
    hidden: false,
  },
  // {
  //   path: "/drivers",
  //   label: "drivers",
  //   component: () => import("@/views/drivers"),
  //   hidden: false,
  // },
  {
    path: "/cars/:id",
    label: "cars",
    component: () => import("@/views/cars/[carId]"),
    hidden: false,
  },
  {
    path: "/services",
    label: "services",
    component: () => import("@/views/services"),
    hidden: false,
  },
  {
    path: "/faq",
    label: "faq",
    component: () => import("@/views/faq"),
    hidden: false,
  },
  {
    path: "/contact",
    label: "contact",
    component: () => import("@/views/contact"),
    hidden: false,
  },
];

export const routes = getRoutes(MASTER_ROUTES);

export const navigation = getNavigation(MASTER_ROUTES);

const baseURL = "/";
export const cars = [
  {
    id: "mercedes-benz-S580",
    name: "2022 Mercedes Benz S580",
    label: "4 Passenger",
    description:
      "Our all new 2022 Mercedes Benz S580 offers our guests unparalleled luxury with state of the art technology.  Simply sit back and say “Hey Mercedes turn on my seat massage” and enjoy a relaxing massage. The power reclining heated/AC seats are the lap of luxury.",
    slogan: "POWER AND SOPHISTICATION",
    imgSrc: baseURL + "cars-img/benz/index.jpg",
    hourly: "$184.99/h plus GST",
    images: [baseURL + "cars-img/benz/images/5.jpg",baseURL + "cars-img/benz/images/6.jpg",baseURL + "cars-img/benz/images/1.jpg", baseURL + "cars-img/benz/images/2.jpg",baseURL + "cars-img/benz/images/3.jpg", baseURL + "cars-img/benz/images/4.jpg",],
  },{
    id: "cadillac-escalade-ESV",
    name: "2023 Cadillac Escalade ESV Platinum Sport ",
    label: "6 Passenger",
    description:
      "Custom ordered from Cadillac with Night Vision, Fridge/Freezer Centre console, 36 Speaker AKG Reference Audio System, Hyper Cruise and Augmented Reality Navigation. Stream Live TV or Movies on our 4K TVs in all of our vehicles.",
    slogan: "POWER AND SOPHISTICATION",
    hourly: "$169.99/h plus GST",
    imgSrc: baseURL + "cars-img/cadillac/index.jpg",
    images: [baseURL + "cars-img/cadillac/images/1.jpg", baseURL + "cars-img/cadillac/images/2.jpg",baseURL + "cars-img/cadillac/images/3.jpg", baseURL + "cars-img/cadillac/images/4.jpg",baseURL + "cars-img/cadillac/images/5.jpg", baseURL + "cars-img/cadillac/images/6.jpg",baseURL + "cars-img/cadillac/images/7.jpg", baseURL + "cars-img/cadillac/images/8.jpg"],
  },
  // {
  //   id: "gmc-denali-ultimate",
  //   name: "2023 GMC Denali XL Ultimate Edition",
  //   label: "6 Passenger",
  //   description:
  //     "DriveUs is proud to be the first Vancouver car service to offer this rare truly luxury full size SUV. You will understand exactly what this is called the “Ultimate Edition” the moment you see her. No options were spared and this luxury SUV is as nice as they come. With room for 6 passengers you will not be disappointed booking this for your next event or ride. Rear Infotainment with dual touch screen TV's, Fridge/Freezer, It’s also equipped with the Thinkware 4K U1000 dual camera dash cameras and live GPS tracking ",
  //   slogan: "POWER AND SOPHISTICATION",
  //   hourly: "$169.99/h plus GST",
  //   imgSrc: baseURL + "cars-img/denali/index.jpg",
  //   images: [baseURL + "cars-img/denali/1.jpg",baseURL + "cars-img/denali/2.jpg",baseURL + "cars-img/denali/3.jpg"],
  // },
  // {
  //   id: "chevrolet-suburban-rst",
  //   name: "2023 Chevrolet Suburban RST",
  //   label: "6 Passenger",
  //   description:
  //     "Our Duramax Suburban is huge with room for 6 guests and luggage this is great for your next airport transfer or event. It’s equipped with a fridge/freezer to keep the complimentary Fiji Water ice cold.    It’s also equipped with the Thinkware 4K U1000 dual camera dash cameras and live GPS tracking.   Whether you are heading out for a round of golf or heading to the airport this is a great choice to get you there in style.  ",
  //   slogan: "POWER AND SOPHISTICATION",
  //   hourly: "$169.99/h plus GST",
  //   imgSrc: baseURL + "cars-img/chevrolet/index.jpg",
  //   images: [baseURL + "cars-img/chevrolet/1.jpg", baseURL + "cars-img/chevrolet/2.jpg", baseURL + "cars-img/chevrolet/3.jpg", baseURL + "cars-img/chevrolet/4.jpg", baseURL + "cars-img/chevrolet/5.jpg"],
  // },
  {
    id: "mercedes-benz-sprinter",
    name: "2022 Mercedes Benz Sprinter 12 Passenger Van",
    label: "12 Passenger",
    description:
      "Our 2022 was delivered new in the summer of 2023. It has room for 11 guests plus the driver. It comes with a built in fridge freezer to keep the complimentary Fiji water ice cold and fast chargers for your cell phones. It also has a built in inverter so you can plug in a laptop or other electrical devices. It comes with dual A/C and heaters for our guests comfort. We also offer high speed 5G Wi-Fi in all our vehicles.",
    slogan: "POWER AND SOPHISTICATION",
    hourly: "$249.99/h plus GST 2 hours min.<br/>Applies  Parking at YVR $25.00",
    smallImg: true,
    imgSrc: baseURL + "cars-img/sprinter/index.jpg",
    images: [baseURL + "cars-img/sprinter/images/1.jpg", baseURL + "cars-img/sprinter/images/2.jpg",baseURL + "cars-img/sprinter/images/3.jpg"],
  },
  // {
  //   id: "cadillac-ct5",
  //   name: "2024 Cadillac CT5 V Series",
  //   label: "4 Passenger",    description:
  //     'PREMIUM LUXURY V Series: Navigation† and Bose†Premium Audio Package, Climate Package, Technology Package, Lighting Package, Parking Package, Semi-Aniline leather seats, leather-wrapped horn pad, interior colour Sedona Sauvage with Jet Black accents with Twenty-Two Carbon Fibre model, driver and front passenger power lumbar massage, driver and front passenger power seatback bolster adjustment, driver and front passenger manual cushion length adjustment and UltraView dual-pane sunroof.<br/><br/><strong>Climate Package:</strong> Heated and ventilated front seats and heated steering wheel<br/><br/><strong>Technology Package:</strong> 12" diagonal HD reconfigurable Driver Cluster and Head-Up Display Sirius Satellite Radio VIOFO 4K dual channel dash cameras and live GPS tracking ',
  //   slogan: "POWER AND SOPHISTICATION",
  //   hourly: "$159.99/h plus GST",
  //   imgSrc: baseURL + "cars-img/cadillac-ct5/index.jpg",
  //   images: [baseURL + "cars-img/cadillac-ct5/images/1.jpg", baseURL + "cars-img/cadillac-ct5/images/2.jpg",baseURL + "cars-img/cadillac-ct5/images/3.jpg", baseURL + "cars-img/cadillac-ct5/images/4.jpg",baseURL + "cars-img/cadillac-ct5/images/5.jpg", baseURL + "cars-img/cadillac-ct5/images/6.jpg",baseURL + "cars-img/cadillac-ct5/images/7.jpg", baseURL + "cars-img/cadillac-ct5/images/8.jpg"],
  // },
  {
    id: "cadillac-escalade-ev",
    name: "2025 Cadillac Escalade IQ EV",
    label: "6 Passenger",
    description:
      "We are thrilled to introduce the all-new 2025 Cadillac Escalade IQ, a groundbreaking evolution of the iconic Escalade—now fully electric. Designed to redefine the standard for luxury SUVs, the Escalade IQ delivers uncompromising performance, innovation, and sustainability in one stunning package.<br/><br/>Crafted with sophisticated design and advanced technology, this all-electric SUV offers the premium comfort, power, and presence you expect from Cadillac—now with a commitment to a greener future. The Escalade IQ boasts an impressive electric range of 740 kms, state-of-the-art Ultium battery technology, and zero tailpipe emissions, allowing you to enjoy the pinnacle of luxury while reducing your carbon footprint.<br/><br/>Step inside and experience an ultra-modern interior featuring next-generation connectivity, handcrafted materials, and a serene cabin that enhances every drive. With its bold presence, exhilarating performance, and cutting-edge electric efficiency, the Escalade IQ is more than an SUV—it’s a statement of progress.<br/><br/>Join us in embracing a new era of electrified luxury. Contact us today to learn more or reserve your Escalade IQ and be among the first to experience the future of Cadillac.”",
    // slogan: "POWER AND SOPHISTICATION",
    hourly: "$189.99/h plus GST",
    comingSoon: true,
    smallImg: true,
    imgSrc: baseURL + "cars-img/escalade-ev/index.jpg",
    images: [baseURL + "cars-img/escalade-ev/images/0.jpg", baseURL + "cars-img/escalade-ev/images/1.jpg", baseURL + "cars-img/escalade-ev/images/2.jpg",baseURL + "cars-img/escalade-ev/images/3.jpg", baseURL + "cars-img/escalade-ev/images/4.jpg", baseURL + "cars-img/escalade-ev/images/5.jpg", baseURL + "cars-img/escalade-ev/images/6.jpg"],
  },
  {
    id: "2024-sprinter",
    name: "2024 Mercedes Benz AWD Sprinter Van",
    label: "12 Passenger",
    smallImg: true,
    description:
      "Whether you are heading to Whistler Blackcomb with your family our 2024 AWD 12 passenger Sprinter will get you there safely.  DriveUs is proud to be the first Vancouver Car Service to offer this amazing AWD Sprinter Van from Mercedes Benz.  We custom ordered this van with all the options possible for our guests comfort and safety. It’s also equipped with fridge/freezer, High Speed 5G Wi Fi, Alexa, High Speed Wireless Charging, Inverter, Dual Roof A/C and Rear Heater, Dual 4K dash cameras and live GPS tracking ",
    slogan: "POWER AND SOPHISTICATION",
    imgSrc: baseURL + "cars-img/sprinter/index.jpg",
    hourly: "$269.99/h plus GST",
    images: [baseURL + "cars-img/sprinter/images/1.jpg", baseURL + "cars-img/sprinter/images/2.jpg",baseURL + "cars-img/sprinter/images/3.jpg"],
  },
];
